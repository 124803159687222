import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { User } from './shared/types/profile';
import { environment } from '../environments/environment';
import imageCompression, { Options } from 'browser-image-compression';

declare var $: any;

export class Utils {

  /**
   * check if a value is nullish
   * @param value
   * @return true if value is undefined or null
   */
  static isNullish(value: any): boolean {
      return value === undefined || value === null;
    }

    static getYoutubeUrl(url: string): string {
        if (!url) { return ''; }

        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\? ]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length == 11) {
            return 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0';
        }

        return '';
    }

    static getHumanDate(
        dateString: string,
        datePipe: DatePipe,
        translateService: TranslateService,
        today: Date = new Date()) {
        let result = '';

        if (dateString) {
            const now = today ?? new Date();
            const date = new Date(dateString);

            if (today && this.isToday(date, today)) {
                result = datePipe.transform(date, 'HH:mm');
            }
            else {
                if (date.getFullYear() == now.getFullYear()) {
                    result = datePipe.transform(date, 'd. {__} HH:mm');
                }
                else {
                    result = datePipe.transform(date, 'd. {__} yyyy HH:mm');
                }

                const month = translateService.instant(`months.${date.getMonth()}`);
                result = result.replace('{__}', month);
            }
        }
        return result;
    }

    static isMe(user: User, otherUser: User): boolean {
      if (!user || !otherUser) { return false; }
      return user._id === otherUser._id;
    }

    static isToday(someDate: Date, today: Date) {
        return someDate.getDate() == today.getDate() &&
          someDate.getMonth() == today.getMonth() &&
          someDate.getFullYear() == today.getFullYear();
    }

    static getAddedText(added: string, translateService: TranslateService) {
        if (!added) {
            return '';
        }

        const now = new Date();
        const diffTime = Math.abs(now.getTime() - new Date(added).getTime());
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays == 0) {
            return translateService.instant('date.added_today');
        }
        else if (diffDays > 365) {
            return translateService.instant('date.added_years_ago').replace('{0}', Math.floor(diffDays / 365));
        }
        else if (diffDays > 30) {
            return translateService.instant('date.added_months_ago').replace('{0}', Math.floor(diffDays / 30));
        }
        else if (diffDays > 6) {
            return translateService.instant('date.added_weeks_ago').replace('{0}', Math.floor(diffDays / 7));
        }
        else {
            return translateService.instant('date.added_days_ago').replace('{0}', diffDays);
        }
    }

    static getLogo() {
        return 'assets/img/logo.png';
    }

    static getLogoMedium() {
        return 'assets/img/logo-medium.png';
    }

    static getLogoSmall() {
        return 'assets/img/logo-small.png';
    }

    static getLineLogo() {
        return 'assets/img/logo-300x60.png';
    }

    static getLineLabelLogo() {
      return 'assets/img/logo-label-300x60.png';
    }

    static getAvatarSmall() {
        return 'assets/img/avatar-sm.png';
    }

    static getAvatarMedium() {
        return 'assets/img/avatar-128.png';
    }

    static getUserAvatarUrl(user: Partial<User>, size: 'preview' | 'small' | 'medium' | 'default' = 'default'): string | undefined {
      const baseUrl = environment.API_URL + '/';
      if (!user.avatar?.avatar) { return undefined; }
      switch (size) {
        case 'default': return baseUrl + user.avatar?.avatar?.url;
        case 'medium': return baseUrl + user.avatar?.avatar?.medium;
        case 'small': return baseUrl + user.avatar?.avatar?.small;
        case 'preview': return baseUrl + user.avatar?.avatar?.previewUrl;
        default: return undefined;
      }
    }

    static getCover() {
        return 'assets/img/cover.png';
    }

    static getFlag(countryCode) {
        return `assets/svg-flags/${countryCode}.svg`;
    }

    static deepCompareObjects(obj1, obj2): boolean {

        if (obj1 instanceof Object) {
            if (!(obj2 instanceof Object)) {
                return false;
            }

            if (Object.keys(obj1).length != Object.keys(obj2).length) {
                return false;
            }

            for (const key of Object.keys(obj1)) {

                if (!obj2.hasOwnProperty( key )) {
                    return false;
                }

                if (!this.deepCompareObjects(obj1[key], obj2[key])) {
                    return false;
                }
            }

            return true;
        }

        if (Array.isArray(obj1)) {
            if (!Array.isArray(obj2)) {
                return false;
            }

            if (obj1.length != obj2.length) {
                return false;
            }

            for (const i of obj1) {
                if (!this.deepCompareObjects(obj1[i], obj2[i])) {
                    return false;
                }
            }

            return true;
        }

        return obj1 === obj2;
    }

    static normalizeString(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    static activeTagsMapToStringArray(tags: Record<string, boolean>): Array<string> {
        return Object.entries(tags).filter(entry => entry[1] === true).map(entry => entry[0]);
    }

    static compressFile(file: File, options?: Options): Promise<File> {
      return imageCompression(file, options || {maxSizeMB: 2, maxWidthOrHeight: 1200, useWebWorker: true});
    }

    static base64ToFile(baseString: string, fileName: string) {
      try {
        const [mime, data] = baseString.split(';base64,');
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mime });

        return new File([blob], fileName, { type: mime });
      } catch (e) {
        console.error(e);
        return null;
      }
    }

    static isValueValidForFilter(value: unknown): boolean {
      if (value === null || value === undefined) { return false; }
      if (typeof value === 'object') { return Object.keys(value).length > 0; }
      if (Array.isArray(value)) { return value.length > 0; }
      if (typeof value === 'number') { return !isNaN(value); }
      return Boolean(value);
    }

    static isValidUrl(string: string): boolean {
      try {
        new URL(string);
        return true;
      } catch (e) {
        return false;
      }
    }
}
