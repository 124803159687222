import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { MessagesComponent } from './components/messages/messages.component';
import { QuickSearchResultComponent } from './components/quick-search-result/quick-search-result.component';
import { HomeComponent } from './home/home.component';
import { MassMessagesComponent } from './mass-messages/mass-messages.component';
import { OrgProfileComponent } from './org-profile/org-profile.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { AboutUsComponent } from './static/about-us/about-us.component';
import { PrivacyPolicyComponent } from './static/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './static/terms-condition/terms-condition.component';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { ProfileHiddenGuard } from './shared/guards/profile-hidden.guard';
import { MassMessagesGuard } from './shared/guards/mass-messages.guard';
import { SuperAdminGuard } from './shared/guards/super-admin.guard';
import { ProfileEditGuard } from './shared/guards/profile-edit.guard';
import { RegisterReferralPageComponent } from './register-referral-page/register-referral-page.component';
import { HelpChildrenPageComponent } from './help-children-page/help-children-page.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'home'},
  {path: 'referral-register', pathMatch: 'full', component: RegisterReferralPageComponent},
  {path: 'help-children', pathMatch: 'full', component: HelpChildrenPageComponent},
  {path: 'home', component: HomeComponent},
  {path: 'home/:tab', component: HomeComponent},
  {path: 'home/:tab/:map', component: HomeComponent},
  {path: 'activate-user', component: ActivateUserComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'profile/:userId', component: ProfileComponent},
  {path: 'profile/:userId/:view', component: ProfileComponent, canActivate: [ProfileEditGuard, ProfileHiddenGuard]},
  {path: 'org-profile/:orgId', component: OrgProfileComponent},
  {path: 'org-profile/:orgId/:view', component: OrgProfileComponent, canActivate: [ProfileEditGuard]},
  {path: 'messages', component: MessagesComponent},
  {path: 'messages/:threadId', component: MessagesComponent},
  {path: 'messages/user/:toUser', component: MessagesComponent},
  {path: 'messages/org/:toOrg', component: MessagesComponent},
  {path: 'org-messages/:orgId', component: MessagesComponent},
  {path: 'org-messages/:orgId/:threadId', component: MessagesComponent},
  {path: 'org-messages/:orgId/user/:toUser', component: MessagesComponent},
  {path: 'org-messages/:orgId/org/:toOrg', component: MessagesComponent},
  {path: 'search', component: QuickSearchResultComponent},
  {path: 'about-us', component: AboutUsComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'terms-and-conditions', component: TermsConditionComponent},
  {path: 'admin', component: SuperAdminComponent, canActivate: [SuperAdminGuard]},
  {path: 'admin/:tab', component: SuperAdminComponent, canActivate: [SuperAdminGuard]},
  {path: 'massmessages', component: MassMessagesComponent, canActivate: [MassMessagesGuard]},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ProfileHiddenGuard]
})
export class AppRoutingModule { }
