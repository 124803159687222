import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { OverlayContainer, OverlayRef } from '@angular/cdk/overlay';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MatPanelService {
  private overlayMutationObserver?: MutationObserver;
  private renderer!: Renderer2;
  private isMobile: boolean = false;
  private scrollListener: () => void;
  private windowResizeListener: () => void;
  private onWindowResize = new Subject<never>();
  private onScroll = new Subject<never>();
  private onWindowResizeSubscription: Subscription;
  private onScrollSubscription: Subscription;

  constructor(
    private overlayContainer: OverlayContainer,
    private rendererFactory: RendererFactory2,
    private breakPointObserver: BreakpointObserver,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.breakPointObserver
      .observe('(max-width: 425px)')
      .subscribe((res) => (this.isMobile = res.matches));
  }

  registerFullscreenPanelListener(): void {
    const overlayContainer: HTMLElement =
      this.overlayContainer.getContainerElement();

    if (!overlayContainer) return;

    this.overlayMutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (this.isMobile && mutation.type === 'childList') {
          this.toggleMobileFullscreenPanel();
        }
      });
    });

    this.overlayMutationObserver.observe(overlayContainer, {
      childList: true,
    });

    this.scrollListener = this.renderer.listen('document', 'scroll', () =>
      this.onScroll.next(),
    );
    this.windowResizeListener = this.renderer.listen('window', 'resize', () =>
      this.onWindowResize.next(),
    );

    this.onWindowResizeSubscription = this.onWindowResize
      .pipe(
        debounceTime(50),
        filter(() => this.isMobile),
      )
      .subscribe(() => this.toggleMobileFullscreenPanel());

    this.onScrollSubscription = this.onScroll
      .pipe(filter(() => this.isMobile))
      .subscribe(() => this.closeOverlay());
  }

  destroy(): void {
    this.windowResizeListener?.();
    this.scrollListener?.();
    this.onScrollSubscription?.unsubscribe();
    this.onWindowResizeSubscription?.unsubscribe();
  }

  private toggleMobileFullscreenPanel(): void {
    const overlayElement: HTMLElement =
      this.overlayContainer.getContainerElement();
    const cdkOverlayPane: HTMLElement =
      overlayElement.querySelector('.cdk-overlay-pane');
    const matSelectPanel: HTMLElement =
      overlayElement.querySelector('.mat-select-panel');
    const autocompleteEl: HTMLElement = overlayElement.querySelector(
      '.mat-autocomplete-panel',
    );

    if (this.isMobile && !autocompleteEl && cdkOverlayPane && matSelectPanel) {
      ['top', 'bottom', 'left', 'transform'].forEach((style) =>
        this.renderer.removeStyle(cdkOverlayPane, style),
      );
    }
  }

  private closeOverlay(): void {
    const overlayElement: HTMLElement =
      this.overlayContainer.getContainerElement();
    const cdkOverlayBackdrop: HTMLElement = overlayElement?.querySelector(
      '.cdk-overlay-backdrop',
    );
    if (cdkOverlayBackdrop) cdkOverlayBackdrop.click();
  }
}
