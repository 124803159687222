import { AbstractControl, ValidationErrors } from '@angular/forms';

export const passwordValidator = (
  control: AbstractControl,
): ValidationErrors => {
  if (control.value.length < 8) {
    return { passwordLength: 'form_error.password_length' };
  }
  return null;
};
