export const environment = {
    production: false,
    build: 'spaceofsports-dev',
    version: '',
    API_URL: '/v1',
    RESEND_ACTIVATION_MAIL: '/auth/resend_activation_code',
    GOOGLE_ANALYTICS_KEY: 'G-6NL6BT6QVC',
    GOOGLE_API_KEY: 'AIzaSyAUAgu7pRO4mLiQEfLRT2I6aCmkFKK0N6U',
    FIREBASE_PWA_CONFIG: {
      apiKey: 'AIzaSyDn_Tlz0oGCXId46bBTaHYxRs0sGjAwwDE',
      authDomain: 'space-of-sports.firebaseapp.com',
      projectId: 'space-of-sports',
      storageBucket: 'space-of-sports.appspot.com',
      messagingSenderId: '172413974414',
      appId: '1:172413974414:web:cb983b7480806f025996df',
      measurementId: 'G-C0VDEKSK84'
    },
    NOTIFICATIONS_CHECK_INTERVAL: 60 * 1000,
    INSTA_PROFILE_URL: 'https://www.instagram.com/space_of_sports'
};
