import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { Utils } from '../../utils';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'mat-select-filter',
  templateUrl: './mat-select-filter.component.html',
  styleUrls: ['./mat-select-filter.component.css'],
  providers: [TranslatePipe],
})
export class MatSelectFilterComponent<Item = any>
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  constructor(private translatePipe: TranslatePipe) {}

  @Input() items: Array<Item> = [];
  @Input() filterKey?: keyof Item;
  @Input() placeholder: string = 'home.search';
  @Input() autoFocus: boolean = true;
  @Input() matSelect?: MatSelect;
  @Output('onFiltered') onFilteredEmitter = new EventEmitter<Array<Item>>();

  @ViewChild('input') inputRef: ElementRef<HTMLInputElement>;

  inputSubject = new Subject<string>();
  subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    this.placeholder = this.translatePipe.transform(this.placeholder);
  }

  ngAfterViewInit(): void {
    if (
      this.items?.length > 1 &&
      typeof this.items[0] === 'object' &&
      !this.filterKey
    ) {
      console.warn(
        '[mat-select-filter] Provided items an array of objects but filterKey is missing',
      );
    }

    this.subscriptions.add(
      this.inputSubject
        .pipe(startWith(''), debounceTime(300), distinctUntilChanged())
        .subscribe((searchText) =>
          this.onFilteredEmitter.emit(this.filterItems(searchText)),
        ),
    );

    this.subscriptions.add(
      this.matSelect?.openedChange.subscribe((open: boolean) =>
        this.onMatSelectOpenChange(open),
      ),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items.currentValue) {
      this.onFilteredEmitter.emit(this.filterItems(''));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onInput(): void {
    if (!this.inputRef) return;
    this.inputSubject.next(this.inputRef.nativeElement.value);
  }

  selectFirst(): void {
    this.matSelect && (this.matSelect.value = this.matSelect.options[0]);
  }

  private filterItems(searchText: string): Array<Item> {
    if (this.filterKey)
      return (
        this.items?.filter((item) => {
          const value = item[this.filterKey];
          const normalizedSearchText = Utils.normalizeString(searchText);
          if (typeof value === 'string')
            return Utils.normalizeString(value)
              .toLowerCase()
              .startsWith(normalizedSearchText.toLowerCase());
          if (typeof value === 'number')
            return Utils.normalizeString(value.toString())
              .toLowerCase()
              .startsWith(normalizedSearchText.toLowerCase());
          return this.items;
        }) || []
      );

    return (
      this.items?.filter((item) => item.toString().startsWith(searchText)) || []
    );
  }

  private clearFilter(): void {
    this.inputRef.nativeElement.value = '';
    this.onFilteredEmitter.emit(this.items);
  }

  private onMatSelectOpenChange(open: boolean) {
    if (open && this.autoFocus) this.inputRef.nativeElement.focus();
    if (!open) this.clearFilter();
  }
}
