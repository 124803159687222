import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { EnumService } from 'src/app/services/enum.service';
import { EventService } from 'src/app/services/event.service';
import { CONSTANTS, getProfileSearchTags } from '../../shared/constants';
import { ProfileSocialLinkPipe } from '../../widgets/profile-info-widget/pipes/profile-social-link.pipe';
import {
  FormattedLang,
  FormattedSport,
  User,
  UserProfile,
} from '../../shared/types/profile';
import { InputPhoneComponent } from '../input-phone/input-phone.component';
import { Tag } from '../../shared/types/options';
import { BaseProfileEditComponent } from '../../base-profile-edit/base-profile-edit.component';
import { BaseEvent, EventType } from '../../shared/types/base';
import { GeocoderService } from '../../services/geocoder.service';
import { ModalService } from '../../services/modal.service';
import { Utils } from '../../utils';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css'],
  providers: [ProfileSocialLinkPipe],
})
export class ProfileEditComponent
  extends BaseProfileEditComponent
  implements OnInit, AfterViewInit
{
  _user: any = null;
  _currentUser: User = null;

  get user(): any {
    return this._user;
  }
  @Input() set user(value: any) {
    this._user = value;
    this.restoreProfile();
  }

  @ViewChild('place') public placeElement: ElementRef;
  @ViewChild('phoneNumberInput') phoneInput: InputPhoneComponent;
  @ViewChild('editConfirmContent') editConfirmContent: TemplateRef<any>;
  @ViewChild('langChipInput') langChipInput: ElementRef<HTMLInputElement>;
  @ViewChild('sportChipInput') sportChipInput: ElementRef<HTMLInputElement>;

  working: boolean = false;

  occupations = [];
  tags: Array<Tag> = [];
  searchTags: Array<Tag> = [];
  profileTags: Array<Tag> = [];

  allLangs = [];
  filteredLangs: Observable<any[]>;
  langControl = new FormControl('');

  allSports: Array<FormattedSport> = [];
  filteredSports: Observable<any[]>;
  filteredMainSports: Array<any> = [];
  sportsControl = new FormControl('');

  data: UserProfile = {};

  fbUrlPattern = CONSTANTS.REGEX.FACEBOOK_URL;
  twitterUrlPattern = CONSTANTS.REGEX.TWITTER_URL;
  instagramRegex = `${CONSTANTS.REGEX.INSTA_URL}|${CONSTANTS.REGEX.SOCIAL_AT_USERNAME}`;
  youtubeRegex = `${CONSTANTS.REGEX.YOUTUBE_URL}|${CONSTANTS.REGEX.SOCIAL_AT_USERNAME}`;
  tiktokRegex = `${CONSTANTS.REGEX.TIKTOK_URL}|${CONSTANTS.REGEX.SOCIAL_AT_USERNAME}`;
  linkedInRegex = CONSTANTS.REGEX.LINKEDIN_URL;

  constructor(
    eventService: EventService,
    enumService: EnumService,
    private apiService: ApiService,
    private ngZone: NgZone,
    private socialLinkPipe: ProfileSocialLinkPipe,
    private geocoderService: GeocoderService,
    private modalService: ModalService,
  ) {
    super(eventService, enumService);
    this.filteredLangs = this.langControl.valueChanges.pipe(
      startWith(''),
      map((lang) => this.filterLangs(lang)),
    );
    this.filteredSports = this.sportsControl.valueChanges.pipe(
      startWith(''),
      map((sport) => this.filterSports(sport)),
    );
  }

  ngOnInit(): void {
    this.data = this.user ? Object.assign({}, this.user.profile) : null;
    this._currentUser = this.apiService.getCurrentUser();
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.geocoderService.onLoad.subscribe((loaded) => {
      if (!loaded) return;
      const autocomplete = new google.maps.places.Autocomplete(
        this.placeElement.nativeElement,
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          this.data.location = autocomplete.getPlace();
        });
      });
    });
    this.phoneInput.setPhoneNumber(this.user.profile.phoneNumber);
  }

  protected eventReceived(event: BaseEvent) {
    if (event.name === EventType.langChange && event.data.languageChanged)
      this.getEnums();
  }

  getEnums(): void {
    super.getEnums();
    let langs = [];
    this.enumService
      .getLanguages()
      ?.forEach((s) => langs.push({ display: s.nativeName, value: s.id }));

    this.allLangs = langs;

    this.occupations = this.enumService.getUserOccupations().map((option) => {
      // hide old enums
      option.visible = !['sport_enthusiast', 'profi_sport'].includes(option.id);
      return option;
    });
    this.tags = this.enumService.getUserTags();
    this.profileTags = this.tags.filter(
      (tag) => !getProfileSearchTags().includes(tag.id),
    );
    this.searchTags = this.tags.filter((tag) =>
      getProfileSearchTags().includes(tag.id),
    );

    this.restoreProfile();
  }

  async saveProfile(): Promise<void> {
    if (
      this._currentUser?.superAdmin &&
      this._currentUser._id !== this._user._id
    ) {
      const ref = this.modalService.showSuperAdminProfileEditWarning(
        this._user,
        'user',
      );
      const confirmed = await ref.closed.toPromise();
      if (!confirmed) return;
    }

    if (!this.data.firstname || !this.data?.lastname || !this.data.birthdate) {
      return;
    }

    if (this.data.facebook && !this.data.facebook.match(this.fbUrlPattern)) {
      return;
    }
    if (this.data.twitter && !this.data.twitter.match(this.twitterUrlPattern)) {
      return;
    }
    if (
      this.data.instagram &&
      !this.data.instagram.match(this.instagramRegex)
    ) {
      return;
    }
    if (this.data.youtube && !this.data.youtube.match(this.youtubeRegex)) {
      return;
    }
    if (this.data.linkedin && !this.data.linkedin.match(this.linkedInRegex)) {
      return;
    }
    if (this.data.tiktok && !this.data.tiktok.match(this.tiktokRegex)) {
      return;
    }
    if (this.data.phoneNumber && !this.phoneInput.phoneControl.valid) {
      return;
    }
    if (this.data.donationsUrl && !Utils.isValidUrl(this.data.donationsUrl)) {
      return;
    }

    if (this.data.location) {
      if (typeof this.data.location.geometry.location.lat === 'function')
        this.data.location.latitude =
          this.data.location.geometry.location.lat();

      if (typeof this.data.location.geometry.location.lng === 'function')
        this.data.location.longitude =
          this.data.location.geometry.location.lng();
    }

    let profile: UserProfile = {
      firstname: this.data.firstname,
      lastname: this.data.lastname,
      gender: this.data.gender,
      birthdate: this.data.birthdate,
      info: this.data.info,
      occupation: this.data.occupation,
      work: this.data.work,
      web: this.data.web,
      donationsUrl: this.data.donationsUrl,
      phoneNumber: this.data.phoneNumber,
      facebook: this.data.facebook,
      twitter: this.data.twitter,
      instagram: this.data.instagram,
      linkedin: this.data.linkedin,
      youtube: this.data.youtube,
      tiktok: this.data.tiktok,
      location: this.data.location,
      defSport: this.data.defSport,
      phoneNumberPublic: this.data.phoneNumberPublic,
      emailPublic: this.data.emailPublic,
    };

    profile.instagram = this.data.instagram
      ? this.socialLinkPipe.transform(profile, 'instagram')
      : undefined;
    profile.linkedin = this.data.linkedin
      ? this.socialLinkPipe.transform(profile, 'linkedin')
      : undefined;
    profile.youtube = this.data.youtube
      ? this.socialLinkPipe.transform(profile, 'youtube')
      : undefined;
    profile.tiktok = this.data.tiktok
      ? this.socialLinkPipe.transform(profile, 'tiktok')
      : undefined;
    profile.height =
      this.data.occupation !== 'sports_fan' ? this.data.height : undefined;
    profile.weight =
      this.data.occupation !== 'sports_fan' ? this.data.weight : undefined;
    profile.sports = this.getAllSports();
    profile.langs = this.data.formatted_langs.map(
      (s: Record<any, string>) => s.value,
    );
    profile.tags = Object.keys(this.data.tags).filter(
      (k) => this.data.tags[k] == true,
    );

    this.working = true;

    const userId = this._currentUser?.superAdmin ? this._user._id : null;

    this.apiService.updateUserProfile(profile, userId).subscribe(
      () => {
        this.working = false;
        this.sendEvent({ name: EventType.update, data: { update: 'profile' } });
        this.sendEvent({
          name: EventType.alert,
          data: {
            type: 'success',
            message: 'profile.update_success',
            dismissible: true,
          },
        });
      },
      (error) => {
        this.working = false;
        this.handleApiError(error);
      },
    );
  }

  restoreProfile(): void {
    if (this.user?.profile) {
      this.data = Object.assign({}, this.user.profile);

      let tags = {};
      this.data.tags?.forEach((t) => {
        tags[t] = true;
      });
      this.data.tags = tags;

      this.setFormattedSports(true);
      this.setFormatedLangs(true);
    } else this.data = null;
  }

  setFormatedLangs(force = false): void {
    let set =
      force ||
      !this.data.formatted_langs ||
      this.data.formatted_langs.length != this.allLangs.length;

    if (set) {
      this.data.formatted_langs = [];
      this.data.langs?.forEach((id) => {
        let lang = this.allLangs.find((s) => s['value'] == id);
        if (lang) {
          this.data.formatted_langs.push(lang);
        }
      });
    }
  }

  onBirthdayChange(date: Date): void {
    this.data.birthdate = date.toISOString();
  }

  onLangChipSelect(event: MatAutocompleteSelectedEvent): void {
    this.data.formatted_langs.push(event.option.value);
    if (this.langChipInput) this.langChipInput.nativeElement.value = '';
  }

  onLangChipRemoved(lang: any): void {
    const index = this.data.formatted_langs.findIndex(
      (language) => language.value === lang.value,
    );
    if (index > -1) this.data.formatted_langs.splice(index, 1);
  }

  onSportChipSelect(event: MatAutocompleteSelectedEvent): void {
    this.data.formatted_sports.push(event.option.value);
    if (this.sportChipInput) this.sportChipInput.nativeElement.value = '';
  }

  onSportChipRemoved(sport: any): void {
    const index = this.data.formatted_sports.findIndex(
      (selectedSport) => selectedSport.value === sport.value,
    );
    if (index > -1) this.data.formatted_sports.splice(index, 1);
  }

  onMainSportFiltered(event: any) {
    this.filteredMainSports = event;
  }

  private filterSports(sport: string): FormattedSport[] {
    if (typeof sport !== 'string') return [];
    const filterVal = sport.toLowerCase();
    return this.allSports.filter(
      (sport) =>
        sport.display.toLowerCase().includes(filterVal) &&
        !this.data.formatted_sports?.find(
          (selectedSport) => sport.value === selectedSport.value,
        ),
    );
  }

  private filterLangs(lang: string): FormattedLang[] {
    if (typeof lang !== 'string') return [];
    const filterVal = lang.toLowerCase();
    return this.allLangs.filter(
      (language) =>
        language.display.toLowerCase().includes(filterVal) &&
        !this.data.formatted_langs?.find(
          (selectedLanguage) => language.value === selectedLanguage.value,
        ),
    );
  }
}
