import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { EventService } from '../services/event.service';
import { EnumService } from '../services/enum.service';
import { FormattedSport, UserProfile } from '../shared/types/profile';

@Component({
  selector: 'app-base-profile-edit',
  templateUrl: './base-profile-edit.component.html',
  styleUrls: ['./base-profile-edit.component.css'],
})
export abstract class BaseProfileEditComponent
  extends BaseComponent
  implements OnInit
{
  data: UserProfile;
  allSports: Array<FormattedSport>;

  protected constructor(
    protected eventService: EventService,
    protected enumService: EnumService,
  ) {
    super(eventService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getEnums();
  }

  onMainSportSelect(selectedSportId: string): void {
    const selectedSport = this.allSports.find(
      (sport) => sport.value === selectedSportId,
    );
    const alreadySelectedSport = this.data.formatted_sports.find(
      (sport) => sport.value === selectedSportId,
    );

    if (alreadySelectedSport) {
      alreadySelectedSport.readonly = true;
      alreadySelectedSport.isMain = true;
      this.data.formatted_sports = this.data.formatted_sports.map((sport) => {
        sport.isMain = sport.value === selectedSportId;
        sport.readonly = sport.value === selectedSportId;
        return sport;
      });
      return;
    }

    if (this.data.formatted_sports.length > 0) {
      const firstSport = this.data.formatted_sports[0];
      if (firstSport.isMain && firstSport.added) {
        if (this.data.formatted_sports.length > 1) {
          this.data.formatted_sports = this.data.formatted_sports.splice(
            1,
            this.data.formatted_sports.length,
          );
        } else {
          this.data.formatted_sports = [];
        }
      }

      this.data.formatted_sports = this.data.formatted_sports.map((sport) => {
        sport.isMain = false;
        sport.readonly = false;
        return sport;
      });
    }

    selectedSport.isMain = true;
    selectedSport.readonly = true;
    selectedSport.added = true;
    this.data.formatted_sports = [selectedSport, ...this.data.formatted_sports];
  }

  getEnums(): void {
    this.allSports = this.enumService.getSports()?.map((s) => {
      return {
        display: s.name,
        value: s.id,
      };
    });
  }

  setFormattedSports(force = false): void {
    let set =
      force ||
      !this.data.formatted_sports ||
      this.data.formatted_sports.length != this.allSports.length;

    if (set) {
      this.data.formatted_sports = this.data.sports?.reduce(
        (sports: Array<any>, id: string) => {
          let sport = this.allSports.find((s) => s.value === id);
          if (sport) {
            sport.added = false;
            sport.isMain = this.data.defSport === id;
            sport.readonly = this.data.defSport === id;
            sports.push(sport);
          }
          return sports;
        },
        [],
      );
    }
  }

  public getAllSports(): string[] {
    const sports: Array<string> = this.data.formatted_sports.map(
      (s) => s.value,
    );
    if (!sports.includes(this.data.defSport) && this.data.defSport)
      sports.unshift(this.data.defSport);
    return sports;
  }
}
