import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { EnumService } from 'src/app/services/enum.service';
import { EventService } from 'src/app/services/event.service';
import { CONSTANTS } from '../../shared/constants';
import { ProfileSocialLinkPipe } from '../../widgets/profile-info-widget/pipes/profile-social-link.pipe';
import { BaseProfileEditComponent } from '../../base-profile-edit/base-profile-edit.component';
import { EventType } from '../../shared/types/base';
import { GeocoderService } from '../../services/geocoder.service';
import { FormattedSport, User } from '../../shared/types/profile';
import { ModalService } from '../../services/modal.service';
import { Utils } from '../../utils';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-org-profile-edit',
  templateUrl: './org-profile-edit.component.html',
  styleUrls: ['./org-profile-edit.component.css'],
  providers: [ProfileSocialLinkPipe],
})
export class OrgProfileEditComponent
  extends BaseProfileEditComponent
  implements OnInit, AfterViewInit
{
  _org: any = null;
  get org(): any {
    return this._org;
  }
  @Input() set org(value: any) {
    this._org = value;
    this._superAdminEdit =
      this.loggedUser?.superAdmin && this._org.owner !== this.loggedUser?._id;
    this.restoreProfile();
  }

  working: boolean = false;
  _superAdminEdit = false;
  loggedUser?: User;

  allSports = [];
  clubTags = [];
  firmTags = [];
  facilityTags = [];
  schoolTags = [];
  availableTags = [];
  orgTypes = [];
  filteredSports: Observable<FormattedSport[]>;
  filteredMainSports: any[] = [];
  sportsControl = new FormControl('');

  data: any = null;

  fbUrlPattern = CONSTANTS.REGEX.FACEBOOK_URL;
  twitterUrlPattern = CONSTANTS.REGEX.TWITTER_URL;
  instagramRegex = `${CONSTANTS.REGEX.INSTA_URL}|${CONSTANTS.REGEX.SOCIAL_AT_USERNAME}`;
  youtubeRegex = `${CONSTANTS.REGEX.YOUTUBE_URL}|${CONSTANTS.REGEX.SOCIAL_AT_USERNAME}`;
  tiktokRegex = `${CONSTANTS.REGEX.TIKTOK_URL}|${CONSTANTS.REGEX.SOCIAL_AT_USERNAME}`;
  linkedInUrlPattern = CONSTANTS.REGEX.LINKEDIN_URL;
  emailPattern = CONSTANTS.REGEX.EMAIL;

  @ViewChild('place') public placeElement: ElementRef;
  @ViewChild('sportChipInput') sportChipInput: ElementRef<HTMLInputElement>;

  constructor(
    eventService: EventService,
    enumService: EnumService,
    private apiService: ApiService,
    private socialLinkPipe: ProfileSocialLinkPipe,
    private ngZone: NgZone,
    private geocoderService: GeocoderService,
    private modalService: ModalService,
  ) {
    super(eventService, enumService);
    this.filteredSports = this.sportsControl.valueChanges.pipe(
      startWith(''),
      map((sport) => this.filterSports(sport)),
    );
  }

  ngOnInit(): void {
    this.loggedUser = this.apiService.getCurrentUser();
    this.data = this.org ? Object.assign({}, this.org.profile) : null;
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.geocoderService.onLoad.subscribe((loaded) => {
      if (!loaded) return;
      const autocomplete = new google.maps.places.Autocomplete(
        this.placeElement.nativeElement,
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          this.data.location = autocomplete.getPlace();
        });
      });
    });
  }

  getEnums() {
    super.getEnums();
    this.clubTags = this.enumService.getClubTags();
    this.firmTags = this.enumService.getFirmTags();
    this.facilityTags = this.enumService.getFacilityTags();
    this.schoolTags = this.enumService.getSchoolTags();
    this.orgTypes = this.enumService.getOrgTypes();
    this.restoreProfile();
  }

  async saveProfile(): Promise<void> {
    if (this._superAdminEdit) {
      const ref = this.modalService.showSuperAdminProfileEditWarning(
        this._org,
        'org',
      );
      const confirmed = await ref.closed.toPromise();
      if (!confirmed) return;
    }

    if (!this.org?._id || !this.data.name) {
      return;
    }

    if (this.data.email && !this.data.email.match(this.emailPattern)) {
      return;
    }
    if (this.data.facebook && !this.data.facebook.match(this.fbUrlPattern)) {
      return;
    }
    if (this.data.twitter && !this.data.twitter.match(this.twitterUrlPattern)) {
      return;
    }
    if (
      this.data.instagram &&
      !this.data.instagram.match(this.instagramRegex)
    ) {
      return;
    }
    if (this.data.youtube && !this.data.youtube.match(this.youtubeRegex)) {
      return;
    }
    if (
      this.data.linkedin &&
      !this.data.linkedin.match(this.linkedInUrlPattern)
    ) {
      return;
    }
    if (this.data.tiktok && !this.data.tiktok.match(this.tiktokRegex)) {
      return;
    }
    if (this.data.donationsUrl && !Utils.isValidUrl(this.data.donationsUrl)) {
      return;
    }

    if (this.data.location) {
      if (typeof this.data.location.geometry.location.lat === 'function')
        this.data.location.latitude =
          this.data.location.geometry.location.lat();

      if (typeof this.data.location.geometry.location.lng === 'function')
        this.data.location.longitude =
          this.data.location.geometry.location.lng();
    }

    let profile = {
      name: this.data.name,
      info: this.data.info,
      email: this.data.email,
      phone: this.data.phone,
      web: this.data.web,
      donationsUrl: this.data.donationsUrl,
      facebook: this.data.facebook,
      twitter: this.data.twitter,
      instagram: this.data.instagram,
      linkedin: this.data.linkedin,
      youtube: this.data.youtube,
      tiktok: this.data.tiktok,
      location: this.data.location,
      defSport: this.data.defSport,
      sports: this.getAllSports(),
      subType: this.data.subType,
      tags: Object.keys(this.data.tags).filter(
        (k) => this.data.tags[k] == true,
      ),
    };

    profile.instagram = this.data.instagram
      ? this.socialLinkPipe.transform(profile, 'instagram')
      : undefined;
    profile.linkedin = this.data.linkedin
      ? this.socialLinkPipe.transform(profile, 'linkedin')
      : undefined;
    profile.youtube = this.data.youtube
      ? this.socialLinkPipe.transform(profile, 'youtube')
      : undefined;
    profile.tiktok = this.data.tiktok
      ? this.socialLinkPipe.transform(profile, 'tiktok')
      : undefined;

    this.working = true;
    this.apiService.updateOrgProfile(this.org._id, profile).subscribe(
      () => {
        this.working = false;
        this.sendEvent({ name: EventType.update, data: { update: 'profile' } });
        this.sendEvent({
          name: EventType.alert,
          data: {
            type: 'success',
            message: 'profile.update_success',
            dismissible: true,
          },
        });
      },
      (error) => {
        this.working = false;
        this.handleApiError(error);
      },
    );
  }

  restoreProfile() {
    if (this.org?.profile) {
      this.data = Object.assign({}, this.org.profile);
      this.data.typeObject = this.orgTypes.find((t) => t.id == this.org.type);

      switch (this.org.type) {
        case 'firm':
          this.availableTags = this.firmTags;
          break;
        case 'sport_facility':
          this.availableTags = this.facilityTags;
          break;
        case 'school':
          this.availableTags = this.schoolTags;
          break;
        default:
          this.availableTags = this.clubTags;
          break;
      }

      let tags = {};
      this.data.tags?.forEach((t) => {
        tags[t] = true;
      });
      this.data.tags = tags;

      this.setFormattedSports(true);
    } else this.data = null;
  }

  onSportChipSelect(event: MatAutocompleteSelectedEvent): void {
    this.data.formatted_sports.push(event.option.value);
    if (this.sportChipInput) this.sportChipInput.nativeElement.value = '';
  }

  onSportChipRemoved(sport: any): void {
    const index = this.data.formatted_sports.findIndex(
      (selectedSport) => selectedSport.value === sport.value,
    );
    if (index > -1) this.data.formatted_sports.splice(index, 1);
  }

  private filterSports(sport: string): FormattedSport[] {
    if (typeof sport !== 'string') return [];
    const filterVal = sport.toLowerCase();
    return this.allSports.filter(
      (sport) =>
        sport.display.toLowerCase().includes(filterVal) &&
        !this.data.formatted_sports.find(
          (selectedSport) => sport.value === selectedSport.value,
        ),
    );
  }
}
