import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { FilterType } from '../components/home-filter/home-filter.component';
import { ApiService } from '../services/api.service';
import { EventService } from '../services/event.service';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.css'],
})
export class SuperAdminComponent extends BaseComponent implements OnInit {
  filter = { country: '', sport: '', tags: [] };

  currentTab: string = '';
  filterType: FilterType = FilterType.Feeds;

  constructor(
    activatedRoute: ActivatedRoute,
    protected eventService: EventService,
    router: Router,
    private apiService: ApiService,
  ) {
    super(eventService);

    activatedRoute.params.subscribe((params) => {
      if (!this.apiService.getCurrentUser()?.superAdmin) {
        router.navigate(['/404'], { skipLocationChange: true });
        return;
      }

      this.currentTab = params['tab'];
      if (!this.currentTab) {
        this.currentTab = 'clubs';
      }

      if (this.currentTab == 'feeds') this.filterType = FilterType.Feeds;
      else if (this.currentTab == 'users') this.filterType = FilterType.Users;
      else if (this.currentTab == 'clubs') this.filterType = FilterType.Clubs;
      else if (this.currentTab == 'firms') this.filterType = FilterType.Firms;
      else if (this.currentTab == 'facilities')
        this.filterType = FilterType.Facilities;
      else if (this.currentTab == 'schools')
        this.filterType = FilterType.Schools;
    });
  }

  ngOnInit(): void {}

  getHeaderText() {
    if (!this.currentTab) return 'home.home';

    return `home.${this.currentTab}`;
  }

  filterChanged($event) {
    this.filter = Object.assign({}, $event?.filter);
  }
}
