<div class="filter-icon-wrapper" *ngIf="_showFilterIcon && _filterCount > 0">
  <div class="filter-icon" role="button" (click)="onFilterIconClick($event)">
    <i class="fa fa-filter"></i>
    <span class="badge">{{ _filterCount }}</span>
  </div>
</div>

<div style="padding:0;" class="col col-lg-12 col-md-12 col-sm-12 col-12 col-centered">
    <div class="ui-block" #filterWrapper>
        <div class="ui-block-content pb-1">
            <ng-content></ng-content>

            <form *ngIf="itemsLoaded">
                <div class="row row-items align-items-center">
                  <div *ngIf="!userPlaces" class="col col-items col-md-6 col-sm-12 col-6">
                    <mat-form-field appearance="outline" class="main-filter-item">
                      <mat-label *ngIf="filter.countries.length === 0">{{'home.all_countries' | translate}}</mat-label>
                      <mat-label *ngIf="filter.countries.length > 0">{{'home.filter_country' | translate}}</mat-label>
                      <mat-select
                        #countrySelect name="country"
                        [(ngModel)]="filter.countries"
                        (selectionChange)="onCountrySelect($event.value)"
                        (ngModelChange)="onChange()"
                        panelClass="flex-panel"
                        multiple
                      >
                        <ng-container *ngIf="itemsLoaded">
                          <div class="select-panel-header">
                            <mat-select-filter
                                [items]="allCountries"
                                [filterKey]="'name'"
                                [matSelect]="countrySelect"
                                (onFiltered)="filteredCountries = $event"
                            ></mat-select-filter>
                          </div>
                          <div class="select-panel-items">
                            <mat-option *ngFor="let country of filteredCountries" [value]="country.code">{{country.name | translate}}
                            </mat-option>
                          </div>

                          <div class="select-panel-footer">
                            <button class="btn btn-sm btn-secondary m-0 mr-2" (click)="clearCountries(); countrySelect.toggle();">{{ 'home.clear_filter'| translate }}</button>
                            <button class="btn btn-sm btn-primary m-0" (click)="countrySelect.toggle()">{{ 'done'| translate }}</button>
                          </div>

                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="userPlaces" class="col col-md-6 col-12 location-group">
                    <mat-form-field appearance="outline" class="mr-2 location-field main-filter-item">
                      <mat-label translate>home.filter_map_place</mat-label>
                      <input matInput #place type="text" name="address">
                    </mat-form-field>
                    <mat-form-field class="flex-shrink-0 radius-field main-filter-item" appearance="outline">
                      <mat-label>{{ 'home.radius' | translate }}</mat-label>
                      <input matInput type="number" name="radius" [(ngModel)]="filter.userRadius">
                    </mat-form-field>
                  </div>

                  <div class="col col-items col-md col-sm-12 col-6">
                    <mat-form-field appearance="outline" class="main-filter-item">
                      <mat-label *ngIf="filter.sports.length === 0">{{'home.all_sports' | translate}}</mat-label>
                      <mat-label *ngIf="filter.sports.length > 0">{{'home.filter_sport' | translate}}</mat-label>
                      <mat-select
                        #sportSelect name="sport"
                        [(ngModel)]="filter.sports"
                        (selectionChange)="onSportSelect($event.value)"
                        (ngModelChange)="onChange()"
                        [placeholder]="'all'"
                        panelClass="flex-panel"
                        multiple
                      >
                        <ng-container *ngIf="itemsLoaded">
                          <div class="select-panel-header">
                            <mat-select-filter
                                [items]="allSports"
                                [filterKey]="'name'"
                                (onFiltered)="filteredSports = $event"
                                [matSelect]="sportSelect"
                            ></mat-select-filter>
                          </div>
                          <div class="select-panel-items">
                            <mat-option *ngFor="let sport of filteredSports" value="{{sport.id}}">{{sport.name}}
                            </mat-option>
                          </div>
                          <div class="select-panel-footer">
                            <button class="btn btn-sm btn-secondary m-0 mr-2" (click)="clearSports(); sportSelect.toggle();">{{ 'home.clear_filter'| translate }}</button>
                            <button class="btn btn-sm btn-primary m-0" (click)="sportSelect.toggle()">{{ 'done'| translate }}</button>
                          </div>
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="showAdvanced" class="col col-items col-md-auto col-sm-12 d-flex clear-filter-wrapper">
                    <button class="btn btn-primary full-width m-0" (click)="clearFilters()">{{ 'home.clear_filter' | translate }}</button>
                  </div>
                </div>

                <div class="row justify-content-center mb-2">
                    <div class="col col-12 no-gutters" #advancedFiltersWrapper #collapse="ngbCollapse" [(ngbCollapse)]="advancedIsCollapsed">

                        <ng-container *ngIf="type === 'map'">
                          <div class="col col-12 mb-2">
                            <mat-label>{{ 'home.clubs' | translate }}</mat-label>
                            <app-tags [tags]="filter.clubTags" [allTags]="tags.clubTags"></app-tags>
                          </div>
                          <div class="col col-12 mb-2">
                            <mat-label>{{ 'home.facilities' | translate }}</mat-label>
                            <app-tags [tags]="filter.facilityTags" [allTags]="tags.facilityTags"></app-tags>
                          </div>
                          <div class="col col-12 mb-2">
                            <mat-label>{{ 'home.firms' | translate }}</mat-label>
                            <app-tags [tags]="filter.firmTags" [allTags]="tags.firmTags"></app-tags>
                          </div>
                          <div class="col col-12 mb-2">
                            <mat-label>{{ 'home.users' | translate }}</mat-label>
                            <app-tags [tags]="filter.userTags" [allTags]="tags.userTags"></app-tags>
                          </div>
                          <div class="col col-12 mb-2">
                            <mat-label>{{ 'home.school' | translate }}</mat-label>
                            <app-tags [tags]="filter.schoolTags" [allTags]="tags.schoolTags"></app-tags>
                          </div>
                        </ng-container>

                        <div *ngIf="superAdmin" class="frow" style="text-align: left; margin-top: 16px;">
                            <div class="col col-12 col-md-6">
                                <mat-form-field appearance="outline">
                                    <mat-select name="active" [(ngModel)]="superAdminFilter.active">
                                        <mat-option value="">{{ 'admin.all' | translate }}</mat-option>
                                        <mat-option *ngIf="type != filterTypes.Users && type != filterTypes.Feeds" value="true">{{ 'admin.active' | translate }}</mat-option>
                                        <mat-option *ngIf="type != filterTypes.Users && type != filterTypes.Feeds" value="false">{{'admin.inactive' | translate }}</mat-option>
                                        <mat-option *ngIf="type == filterTypes.Users || type == filterTypes.Feeds" value="true">{{ 'admin.nonblocked' | translate }}</mat-option>
                                        <mat-option *ngIf="type == filterTypes.Users || type == filterTypes.Feeds" value="false">{{'admin.blocked' | translate }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div *ngIf="type != filterTypes.Feeds" class="col col-12 col-md-6">
                                <mat-form-field appearance="outline">
                                    <input matInput type="text" style="padding: 0;"
                                        [(ngModel)]="superAdminFilter.name" name="name"
                                        placeholder="{{'admin.search_name' | translate}}">
                                </mat-form-field>
                            </div>

                            <div *ngIf="type == filterTypes.Feeds" class="col col-12 col-md-6 align-self-center">
                                <app-tags [allTags]="superAdminTags" [tags]="advancedFilter.feedTypes" class="full-width"></app-tags>
                            </div>
                        </div>

                        <div class="row" style="text-align: left; margin-top: 16px;">

                            <!-- user fields -->
                            <ng-container *ngIf="advancedFilter.user">
                                <div class="col col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label translate>profile.gender</mat-label>
                                        <mat-select name="gender" [(ngModel)]="advancedFilter.user.gender">
                                            <mat-option value="" translate>-</mat-option>
                                            <mat-option value="male" translate>{{ 'profile.male' | translate }}
                                            </mat-option>
                                            <mat-option value="female" translate>{{ 'profile.female' | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label translate>home.filter_user_is_member</mat-label>
                                        <mat-select name="isMember" [(ngModel)]="advancedFilter.user.isMember">
                                            <mat-option value="" translate>-</mat-option>
                                            <mat-option value="isMember" translate>{{ 'home.club_member' | translate }}
                                            </mat-option>
                                            <mat-option value="notMember" translate>{{ 'home.not_club_member' |
                                                translate }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label translate>home.min_age</mat-label>
                                        <input matInput type="number" name="minAge"
                                            [(ngModel)]="advancedFilter.user.minAge">
                                    </mat-form-field>
                                </div>

                                <div class="col col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label translate>home.max_age</mat-label>
                                        <input matInput type="number" name="maxAge"
                                            [(ngModel)]="advancedFilter.user.maxAge">
                                    </mat-form-field>
                                </div>

                                <div class="col col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label translate>home.min_height</mat-label>
                                        <input matInput type="number" name="height"
                                            [(ngModel)]="advancedFilter.user.minHeight">
                                    </mat-form-field>
                                </div>

                                <div class="col col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label translate>home.max_height</mat-label>
                                        <input matInput type="number" name="height"
                                            [(ngModel)]="advancedFilter.user.maxHeight">
                                    </mat-form-field>
                                </div>

                                <div class="col col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label translate>home.min_weight</mat-label>
                                        <input matInput type="number" name="weight"
                                            [(ngModel)]="advancedFilter.user.minWeight">
                                    </mat-form-field>
                                </div>

                                <div class="col col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label translate>home.max_weight</mat-label>
                                        <input matInput type="number" name="weight"
                                            [(ngModel)]="advancedFilter.user.maxWeight">
                                    </mat-form-field>
                                </div>

                                <div class="col col-12">&nbsp;</div>

                            </ng-container>

                            <div class="col col-12 mb-2">
                              <app-tags [tags]="advancedFilter.tags" [allTags]="tags.currentTags" [labelKey]="'filterName'"></app-tags>
                            </div>

                        </div>

                        <div style="width: 100%; text-align: right;">
                            <button class="btn btn-primary" (click)="searchAdvancedFilter()"
                                translate>home.search_btn</button>
                        </div>
                    </div>

                    <div class="row" *ngIf="showAdvanced && type == filterTypes.Users || type == filterTypes.Map">
                      <div class="col">
                        <button class="btn btn-link btn-block" (click)="collapse.toggle()">
                          <i class="fa fa-lg mr-2" [ngClass]="advancedIsCollapsed ? 'fa-plus-circle' : 'fa-minus-circle'"></i>
                          <span class="align-baseline">{{ 'home.advanced_filter' | translate }}</span>
                        </button>
                      </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
