import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/base/base.component';
import { ApiService } from 'src/app/services/api.service';
import { EnumService } from 'src/app/services/enum.service';
import { EventService } from 'src/app/services/event.service';
import { Utils } from 'src/app/utils';
import { ChoosePhotoComponent } from '../choose-photo/choose-photo.component';
import { SelectDefOrgComponent } from '../select-def-org/select-def-org.component';
import { MenuItem } from '../../shared/types/menu';
import { EventType } from '../../shared/types/base';
import { OrganizationType, User } from '../../shared/types/profile';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.css'],
})
export class ProfileHeaderComponent extends BaseComponent implements OnInit {
  loggedUser: User;
  _user: any = null;
  get user(): any {
    return this._user;
  }
  @Input() set user(value: any) {
    this._user = value;
    this.userUpdated();
    this.superAdminEdit =
      this.loggedUser?.superAdmin && this._user._id !== this.loggedUser._id;
  }

  _org: any = null;
  get org(): any {
    return this._org;
  }
  @Input() set org(value: any) {
    this._org = value;
    this.superAdminEdit =
      this.loggedUser?.superAdmin && this._org.owner !== this.loggedUser._id;
    this.orgUpdated();
  }

  @Input() editable: boolean = false;
  superAdminEdit = false;

  photos = null;
  baseUrl: string = '';
  avatarUrl: string = '';
  coverUrl: string = '';
  flagUrl: string = '';
  defUserOrg: any = null;
  defSport: string = '';
  otherSports: string[] = [];
  allowInvite: boolean = false;
  isUserOrgAdmin: boolean = false;
  hasUserMoreOrgs: boolean = false;

  working: boolean = false;
  mobileMenuItems: MenuItem[] = [];
  orgMobileMenuItems: MenuItem[];

  constructor(
    eventService: EventService,
    private enumService: EnumService,
    private apiService: ApiService,
    private ngbModal: NgbModal,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private modalService: ModalService,
  ) {
    super(eventService);
  }

  ngOnInit(): void {
    this.loggedUser = this.apiService.getCurrentUser();
    if (!this.editable) {
      this.loggedUser?.adminOrgs?.forEach((o) => {
        if (o.active) {
          let member = o.members.find((m) => m.userId == this.user?._id);
          if (!member || (member?.acceptedByUser && !member?.acceptedByOrg)) {
            this.allowInvite = true;
            return;
          }
        }
      });
    }
  }

  userUpdated() {
    this.baseUrl = `/profile/${this.user?._id}/`;
    this.updateTabPaths();
    this.photos = this.user?.photos;

    this.avatarUrl = ApiService.getPhotoUrl(
      this.user?.avatar?.avatar?.medium,
      Utils.getAvatarMedium(),
    );
    this.coverUrl = ApiService.getPhotoUrl(
      this.user?.avatar?.cover?.url,
      Utils.getCover(),
    );
    this.flagUrl = this.user?.profile?.country
      ? Utils.getFlag(this.user?.profile?.country)
      : null;

    let acceptedOrgs = this.user?.organizations?.filter((o) => o.accepted);
    this.hasUserMoreOrgs = acceptedOrgs ? acceptedOrgs.length > 1 : false;
    this.defUserOrg =
      acceptedOrgs?.find((o) => o._id == this.user?.defOrg) ??
      acceptedOrgs?.find((o) => o.accepted);

    if (this.defUserOrg) {
      this.defUserOrg.absoluteAvatarUrl = ApiService.getPhotoUrl(
        this.defUserOrg.avatar?.avatar?.medium,
        Utils.getLogoMedium(),
      );
    }

    let allSports = this.enumService.getSports();
    let defSport = this.user?.profile?.defSport;
    let otherSports = this.user?.profile?.sports?.filter(
      (s) => s != this.user?.profile?.defSport,
    );

    if (!defSport && otherSports && otherSports.length > 0) {
      this.defSport = otherSports[0];
    }

    if (defSport) {
      this.defSport =
        allSports?.find((s) => s.id == defSport)?.name ?? defSport;
    }

    if (otherSports) {
      this.otherSports =
        allSports
          ?.filter((s) => otherSports.includes(s.id))
          ?.map((s) => s.name) ?? [];
    }
  }

  orgUpdated() {
    this.isUserOrgAdmin = this.apiService.isCurrentUserAdmin(this.org?._id);
    this.baseUrl = `/org-profile/${this.org?._id}/`;
    this.updateTabPaths();
    this.photos = this.org?.photos;

    this.avatarUrl = ApiService.getPhotoUrl(
      this.org?.avatar?.avatar?.medium,
      Utils.getLogoMedium(),
    );
    this.coverUrl = ApiService.getPhotoUrl(
      this.org?.avatar?.cover?.url,
      Utils.getCover(),
    );
    this.flagUrl = this.org?.profile?.country
      ? Utils.getFlag(this.org?.profile?.country)
      : null;

    let allSports = this.enumService.getSports();
    let defSport = this.org?.profile?.defSport;
    let otherSports = this.org?.profile?.sports?.filter(
      (s) => s != this.org?.profile?.defSport,
    );

    if (!defSport && otherSports && otherSports.length > 0) {
      this.defSport = otherSports[0];
    }

    if (defSport) {
      this.defSport =
        allSports?.find((s) => s.id == defSport)?.name ?? defSport;
    }

    if (otherSports) {
      this.otherSports =
        allSports
          ?.filter((s) => otherSports.includes(s.id))
          ?.map((s) => s.name) ?? [];
    }
  }

  setDefaultOrganization() {
    let modalRef = this.ngbModal.open(SelectDefOrgComponent, {
      centered: true,
    });
    modalRef.componentInstance.orgs = this.user?.organizations;
    modalRef.componentInstance.defOrgId = this.user?.defOrg;
    modalRef.componentInstance.defOrgSelected.subscribe((event) => {
      this.setWorking(false);
      this.apiService.setDefaultOrg(event.orgId).subscribe(
        (result) => {
          this.setWorking(false);
          if (this.user) {
            this.user.defOrg = event.orgId;
            this.userUpdated();
          }
        },
        (error) => {
          this.handleApiError(error);
          this.setWorking(false);
        },
      );
    });
  }

  async updateProfilePhoto(): Promise<void> {
    if (this.superAdminEdit) {
      const confirmed = await this.modalService
        .showSuperAdminProfileEditWarning(
          this._user || this._org,
          this._user ? 'user' : 'org',
        )
        .closed.toPromise();
      if (!confirmed) return;
    }

    let modalRef = this.ngbModal.open(ChoosePhotoComponent, { size: 'lg' });
    modalRef.componentInstance.photos = this.photos;
    modalRef.componentInstance.croppingMode = 'profile-picture';
    modalRef.componentInstance.headerText = 'profile.update_profile_photo';
    modalRef.componentInstance.photoSelected.subscribe((event) => {
      this.setPhoto('avatar', event);
    });
  }

  async updateCoverPhoto(): Promise<void> {
    if (this.superAdminEdit) {
      const confirmed = await this.modalService
        .showSuperAdminProfileEditWarning(
          this._user || this._org,
          this._user ? 'user' : 'org',
        )
        .closed.toPromise();
      if (!confirmed) return;
    }

    let modalRef = this.ngbModal.open(ChoosePhotoComponent, { size: 'lg' });
    modalRef.componentInstance.photos = this.photos;
    modalRef.componentInstance.croppingMode = 'title-photo';
    modalRef.componentInstance.headerText = 'profile.update_cover_photo';
    modalRef.componentInstance.photoSelected.subscribe((event) => {
      this.setPhoto('cover', event);
    });
  }

  setPhoto(type, event) {
    const entityId = this.superAdminEdit
      ? this._user?._id || this._org?._id
      : null;
    if ('photo' in event) {
      this.setPhotoId({ [type]: event.photo.id }, entityId);
    } else if (event.files && event.files.length > 0) {
      const file: File = event.files[0];

      const ext = file.name.split('.').pop();
      const formData = new FormData();
      formData.append('file', file, `myimage.${ext}`);
      formData.append('name', 'photo_file');
      formData.append('album', 'true');

      this.setWorking(true, 'progress_uploading_photo');

      if (this.org) {
        this.apiService.uploadOrgPhotos(this.org._id, formData).subscribe(
          (result) => {
            this.setWorking(false);
            let ids = result.body['ids'];
            if (ids.length > 0) this.setPhotoId({ [type]: ids[0] });
          },
          (error) => {
            this.handleApiError(error);
            this.setWorking(false);
          },
        );
      } else {
        this.apiService.uploadUserPhotos(formData, entityId).subscribe(
          (result) => {
            this.setWorking(false);
            let ids = result.body['ids'];
            if (ids.length > 0) this.setPhotoId({ [type]: ids[0] }, entityId);
          },
          (error) => {
            this.handleApiError(error);
            this.setWorking(false);
          },
        );
      }
    }
  }

  setPhotoId(data, entityId?: string) {
    if (this.org) {
      this.apiService.updateOrgProfilePhoto(this.org._id, data).subscribe(
        () => {
          this.profileUpdated();
        },
        (error) => this.handleApiError(error),
      );
    } else {
      this.apiService.updateUserProfilePhoto(data, entityId).subscribe(
        () => {
          this.profileUpdated();
        },
        (error) => this.handleApiError(error),
      );
    }
  }

  setWorking(working: boolean, message: string = '') {
    this.working = working;

    if (working) {
      let msg = this.translateService.instant(message);
      this.snackBar?.open(msg, null, { duration: 0 });
    } else {
      this.snackBar?.dismiss();
    }
  }

  profileUpdated() {
    this.sendEvent({ name: EventType.update, data: { update: 'profile' } });
  }

  updateTabPaths(): void {
    if (this.user) {
      this.mobileMenuItems = [
        {
          label: 'profile.referrals',
          path: this.baseUrl + 'referrals',
          active: false,
          visible: this.loggedUser?._id === this._user._id,
        },
        {
          label: 'profile.timeline',
          path: this.baseUrl + 'feeds',
          active: false,
          visible: true,
        },
        {
          label: 'profile.photos',
          path: this.baseUrl + 'photos',
          active: false,
          visible: true,
        },
      ];
    }

    if (this.org) {
      if (this.org.type === OrganizationType.hall_of_fame) {
        this.orgMobileMenuItems = [
          {
            label: 'profile.legends',
            path: this.baseUrl + 'legends',
            active: false,
            visible: true,
          },
          {
            label: 'profile.legends_requests',
            path: this.baseUrl + 'legends-manage',
            active: false,
            visible: this.isUserOrgAdmin,
          },
        ];
      } else {
        this.orgMobileMenuItems = [
          {
            label: 'profile.timeline',
            path: this.baseUrl + 'feeds',
            active: false,
            visible: true,
          },
          {
            label: 'about_us.title',
            path:
              this.baseUrl +
              (this.isUserOrgAdmin ? 'edit-profile' : 'view-profile'),
            active: false,
            visible: true,
          },
          {
            label: 'profile.members',
            path: this.baseUrl + 'members',
            active: false,
            visible: true,
          },
          {
            label: 'profile.photos',
            path: this.baseUrl + 'photos',
            active: false,
            visible: true,
          },
          {
            label: 'profile.messages',
            path: '/org-messages/' + this.org._id,
            active: false,
            visible: this.isUserOrgAdmin,
          },
        ];
      }
    }
  }
}
